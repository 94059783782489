import React, { useEffect, useRef } from 'react';
import { fabric } from 'fabric';
import deleteIcon from '../icons/delete-icon';

import '../styles/fabric-canvas.scss';

export type DrawingMode = 'brush' | 'rectangle';

interface canvasMask {
    width: number | undefined;
    height: number | undefined;
    naturalWidth: number | undefined;
    naturalHeight: number | undefined;
    enableCreateMaskCallback: boolean;
    createBase64MaskCallback: (base64Mask: string | undefined) => void;
}
export interface FabricCanvasSize {
    width: number;
    height: number;
}

interface Props {
    drawingColor?: string;
    brushSize?: number;
    canvasSize?: FabricCanvasSize;
    drawingMode?: DrawingMode;
    isDrawingEnabled?: boolean;
    className?: string;
    resetDrawings?: boolean;
    canvasMask: canvasMask;
    resetDrawingsCallback?: () => void;
    onMouseDown?: (event) => void;
    onMouseMove?: (event) => void;
    onMouseUp?: (event) => void;
}

export const FabricCanvas: React.FC<Props> = ({
    drawingColor = 'black',
    brushSize = 20,
    canvasSize = { width: 300, height: 300 },
    drawingMode = 'brush',
    isDrawingEnabled = true,
    className,
    resetDrawings = false,
    canvasMask,
    resetDrawingsCallback,
    onMouseDown,
    onMouseMove,
    onMouseUp
}) => {
    const isDrawing = useRef(false);
    const startX = useRef(0);
    const startY = useRef(0);
    const canvasRef = useRef<fabric.Canvas | null>(null);
    const circleCursorRef = useRef<HTMLDivElement | null>(null);
    const drawingModeRef = useRef<DrawingMode>(drawingMode);

    const deleteImg = document.createElement('img');
    deleteImg.src = deleteIcon;

    /** Draw icon on the canvas. */
    const renderIcon = (icon: HTMLImageElement) => {
        return function (ctx: CanvasRenderingContext2D, left: number, top: number, styleOverride: any, fabricObject: fabric.Object) {
            const size = 24; // Icon size.
            ctx.save();
            ctx.translate(left, top);
            ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle ?? 0));
            ctx.drawImage(icon, -size / 2, -size / 2, size, size);
            ctx.restore();
        };
    };

    /** Delete select object when clicking the delete icon */
    const deleteObject = (eventData: MouseEvent, transformData: fabric.Transform, x: number, y: number) => {
        const target = transformData.target;
        if (target) {
            const canvas = target.canvas;
            canvas?.remove(target);
            canvas?.requestRenderAll();
            return true; // Deletion successful
        }
        return false; // No object to delete
    };

    // Set delete control.
    fabric.Object.prototype.controls.deleteControl = new fabric.Control({
        x: 0.5,
        y: -0.5,
        offsetY: -16,
        offsetX: 16,
        cursorStyle: 'pointer',
        mouseUpHandler: deleteObject,
        render: renderIcon(deleteImg)
    });

    const createBase64MaskFile = () => {
        const base64 = getMaskFromCanvasCopy(); // Create a copy of the canvas with the right mask colors.
        canvasMask.createBase64MaskCallback(base64); // Trigger the callback function.
    };

    const getMaskFromCanvasCopy = () => {
        // Calculate scaling factors
        const { naturalHeight, naturalWidth, width, height } = canvasMask;

        if (!naturalHeight || !naturalWidth || !width || !height) {
            return;
        }

        const widthRatio = naturalWidth / width;
        const heightRatio = naturalHeight / height;

        // Initialize canvas with a scaling factor based on the calculated ratios
        const scalingFactor = Math.min(widthRatio, heightRatio); // You can adjust this based on your requirements

        const newCanvasElement = document.createElement('canvas');

        const canvasCopy = new fabric.Canvas(newCanvasElement, {
            isDrawingMode: drawingModeRef.current === 'brush',
            width: canvasMask.naturalWidth,
            height: canvasMask.naturalHeight
        });

        // Iterate through source canvas objects
        canvasRef.current?.getObjects().forEach((obj) => {
            // Clone the object and add it to the copy canvas
            obj.clone((clonedObj: fabric.Object) => {
                const scalingFactor = 1; // Can be use the scale the drawing bigger

                const scaleX = clonedObj.scaleX && clonedObj.scaleX * scalingFactor;
                const scaleY = clonedObj.scaleY && clonedObj.scaleY * scalingFactor;

                clonedObj.set({ scaleX, scaleY });

                if (clonedObj.type === 'path') {
                    clonedObj.set({
                        scaleX,
                        scaleY,
                        stroke: '#FFFFFF' // Set all lines to white, this is necessary for the mask.
                    });
                } else {
                    clonedObj.set({
                        scaleX,
                        scaleY,
                        stroke: '#FFFFFF',
                        fill: '#FFFFFF' // Set all shapes to white, this is necessary for the mask.
                    });
                }

                canvasCopy.add(clonedObj);
            });
        });

        canvasCopy.setBackgroundColor('black', canvasCopy.renderAll.bind(canvasCopy)); // Set back ground to black, this is necessary for the mask.

        canvasCopy.setZoom(scalingFactor);

        // Redraw the canvas after modifying colors
        canvasCopy.renderAll();

        const base64 = canvasCopy.toDataURL(); // Convert the canvas content to base64 as a png type

        canvasCopy.dispose(); // Dispose the canvas copy.

        return base64;
    };

    const clearDrawings = () => {
        startX.current = 0;
        startY.current = 0;

        // Clear the canvas by resetting its content
        const canvas = canvasRef.current;

        if (!canvas) {
            return;
        }

        canvas.clear();

        if (resetDrawingsCallback) {
            resetDrawingsCallback();
        }
    };

    /**
     * Handles the mouse move event for the brush tool.
     * Updates the position of the circle cursor element to follow the mouse cursor.
     * @param event - The mouse event object.
     */
    const onMouseBrushMove = ({ e }: fabric.IEvent<MouseEvent>) => {
        const circleCursorElement = circleCursorRef.current;
        const canvasElement = canvasRef?.current?.getElement();

        if (!circleCursorElement || !canvasElement) {
            return;
        }

        const canvasRect = canvasElement.getBoundingClientRect();
        const mouseY = e.clientY - canvasRect.top; // Adjust for canvas position
        const mouseX = e.clientX - canvasRect.left; // Adjust for canvas position

        // Calculate the offset needed to center the circle cursor
        const cursorOffsetX = circleCursorElement.offsetWidth / 2;
        const cursorOffsetY = circleCursorElement.offsetHeight / 2;

        // Calculate the final position to center the circle cursor
        const finalX = mouseX - cursorOffsetX;
        const finalY = mouseY - cursorOffsetY;

        circleCursorElement.style.left = `${finalX}px`;
        circleCursorElement.style.top = `${finalY}px`;
    };

    useEffect(() => {
        if (resetDrawings) {
            clearDrawings();
        }
    }, [resetDrawings]);

    useEffect(() => {
        if (canvasMask.enableCreateMaskCallback) {
            createBase64MaskFile();
        }
    }, [canvasMask.enableCreateMaskCallback]);

    useEffect(() => {
        drawingModeRef.current = drawingMode; // Set drawing mode ref to the current drawing mode.
        const circleCursorElement = circleCursorRef.current;

        if (!circleCursorElement || !canvasRef.current) {
            return;
        }

        if (drawingModeRef.current === 'brush') {
            circleCursorElement.style.display = 'block'; // Show circle cursor when in brush mode.
            canvasRef.current.freeDrawingCursor = 'none'; // Remove the default cursor when using the brush.
            circleCursorElement.style.width = `${brushSize}px`; // Set the latest brush width.
            circleCursorElement.style.height = `${brushSize}px`; // Set the latest brush height.
            circleCursorElement.style.border = '2px solid white'; // Set the border to white.
            circleCursorElement.style.boxShadow = '0px 2px 4px rgba(0, 0, 0, 0.10)'; // Set the box shadow.
        } else {
            circleCursorElement.style.display = 'none';
            canvasRef.current.defaultCursor = 'crosshair'; // Set crosshair when using the rectangle.
        }
    }, [drawingMode, brushSize, canvasRef.current]);

    useEffect(() => {
        if (!canvasRef.current) {
            canvasRef.current = new fabric.Canvas('canvas-id', {
                isDrawingMode: drawingModeRef.current === 'brush',
                width: canvasSize.width,
                height: canvasSize.height,
                selectionColor: drawingColor
            });

            canvasRef.current.freeDrawingBrush.color = drawingColor;
            canvasRef.current.freeDrawingBrush.width = brushSize;

            // Listen to the object:added event
            canvasRef.current.on('mouse:up', (event) => {
                if (!canvasRef.current?.isDrawingMode && isDrawing.current) {
                    const canvasElement = canvasRef?.current?.getElement();

                    if (!canvasElement || !canvasRef.current) {
                        return;
                    }

                    const currentX = event.e.clientX - canvasElement.getBoundingClientRect().left;
                    const currentY = event.e.clientY - canvasElement.getBoundingClientRect().top;

                    const width = currentX - startX.current;
                    const height = currentY - startY.current;

                    const left = width < 0 ? currentX : startX.current;
                    const top = height < 0 ? currentY : startY.current;
                    const absWidth = Math.abs(width);
                    const absHeight = Math.abs(height);

                    if (absWidth !== 0 && absHeight !== 0) {
                        const rect = new fabric.Rect({
                            left: left,
                            top: top,
                            stroke: drawingColor,
                            strokeWidth: 1,
                            width: absWidth,
                            height: absHeight,
                            fill: drawingColor
                        });

                        canvasRef.current.add(rect);
                    }

                    isDrawing.current = false;
                }

                if (onMouseUp) {
                    onMouseUp(event);
                }
            });

            canvasRef.current.on('mouse:down', (event) => {
                if (canvasRef.current?.getActiveObject()) {
                    return; // Don't start drawing if there's an active object (selection)
                }

                // If isDrawingMode false then start drawing rectangle
                if (!canvasRef.current?.isDrawingMode) {
                    isDrawing.current = true;

                    const canvasElement = canvasRef.current?.getElement();

                    if (!canvasElement) {
                        return;
                    }

                    startX.current = event.e.clientX - canvasElement.getBoundingClientRect().left;
                    startY.current = event.e.clientY - canvasElement.getBoundingClientRect().top;
                }

                if (onMouseDown) {
                    onMouseDown(event);
                }
            });

            canvasRef.current.on('mouse:move', (event) => {
                if (canvasRef.current?.isDrawingMode) {
                    onMouseBrushMove(event);
                }

                if (onMouseMove) {
                    onMouseMove(event);
                }
            });
        } else {
            // Update brush size when it changes
            canvasRef.current.freeDrawingBrush.width = brushSize;
            canvasRef.current.setDimensions({ width: canvasSize.width, height: canvasSize.height });
            canvasRef.current.isDrawingMode = drawingModeRef.current === 'brush';
            canvasRef.current.renderAll();
        }
    }, [drawingMode, canvasSize, brushSize]);

    /** Removed selected object from the canvas, when user clicks the delete or backspace. */
    const removeSelectedObject = () => {
        // Get the active object
        const activeObject = canvasRef.current?.getActiveObject();

        // If there's an active object, remove it
        if (activeObject) {
            canvasRef.current?.remove(activeObject);
            canvasRef.current?.renderAll();
        }
    };

    const onKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Backspace' || event.key === 'Delete') {
            removeSelectedObject();
        }
    };

    useEffect(() => {
        // Add event listener for 'keydown' event
        window.addEventListener('keydown', onKeyDown);

        return () => {
            // Remove event listeners
            window.removeEventListener('keydown', onKeyDown);

            // Cleanup function: Dispose the main canvas.
            if (canvasRef.current) {
                canvasRef.current.dispose();
                canvasRef.current = null;
            }
        };
    }, []);

    return (
        <div className={`fabric-canvas ${className}`} style={{ display: isDrawingEnabled ? 'block' : 'none' }}>
            <canvas id="canvas-id" style={{ cursor: 'none !important' }}></canvas>
            <div className="fabric-canvas__circle-cursor" ref={circleCursorRef} />
        </div>
    );
};

export default FabricCanvas;
