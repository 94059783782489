import { SelectedArea, ImagePosition, Point, ResizeState, DragState, Layer } from '../interfaces/outpaint';

const adjustLeft = (dx: number, minWidth: number, newWidth: number, newX: number) => {
    newWidth = newWidth - dx;
    newX = newX + dx;
    return { newWidth, newX };
};

const adjustRight = (dx: number, newWidth: number) => {
    newWidth = newWidth + dx;
    return { newWidth };
};

const adjustTop = (dy: number, minHeight: number, newHeight: number, newY: number) => {
    newHeight = newHeight - dy;
    newY = newY + dy;
    return { newHeight, newY };
};

const adjustBottom = (dy: number, minHeight: number, newHeight: number) => {
    newHeight = newHeight + dy;
    return { newHeight };
};

/**
 * Determine if the pointer is in the selected area
 */
const isPointerInSelectedArea = (
    pointerCoords: Point,
    imagePosition: ImagePosition,
    selectedArea: SelectedArea,
    padding = 10
): { isInSelectedArea: boolean; layer: Layer } => {
    // if the pointer is in the selected area
    if (
        selectedArea &&
        pointerCoords.x > selectedArea.x + padding &&
        pointerCoords.x < selectedArea.x + selectedArea.width - padding &&
        pointerCoords.y > selectedArea.y + padding &&
        pointerCoords.y < selectedArea.y + selectedArea.height - padding
    ) {
        // If the pointer is in the image
        if (
            imagePosition &&
            pointerCoords.x > imagePosition.x + padding &&
            pointerCoords.x < imagePosition.x + imagePosition.width - padding &&
            pointerCoords.y > imagePosition.y + padding &&
            pointerCoords.y < imagePosition.y + imagePosition.height - padding
        ) {
            // Return when pointer is inside the image
            return { isInSelectedArea: true, layer: 'image' };
        }
        // Return when pointer is inside the selected area but outside the image
        return { isInSelectedArea: false, layer: '' };
    } else {
        // If the pointer is in the image
        if (
            imagePosition &&
            pointerCoords.x > imagePosition.x + padding &&
            pointerCoords.x < imagePosition.x + imagePosition.width - padding &&
            pointerCoords.y > imagePosition.y + padding &&
            pointerCoords.y < imagePosition.y + imagePosition.height - padding
        ) {
            // Return when pointer is inside the image but outside the selection
            return { isInSelectedArea: true, layer: 'image' };
        }
    }

    // return if pointer is outside image and selection
    return { isInSelectedArea: false, layer: '' };
};

const isPointerOnSelectedAreaEdge = (pointerCoords: Point, imagePosition: ImagePosition, selectedArea: SelectedArea, padding: number) => {
    if (!selectedArea || !imagePosition) return { isOnEdge: false, edge: '', layer: '' };

    const { x, y, width, height } = selectedArea;
    const leftEdgeSelectedArea = Math.abs(pointerCoords.x - x) <= padding;
    const rightEdgeSelectedArea = Math.abs(pointerCoords.x - (x + width)) <= padding;
    const topEdgeSelectedArea = Math.abs(pointerCoords.y - y) <= padding;
    const bottomEdgeSelectedArea = Math.abs(pointerCoords.y - (y + height)) <= padding;

    const leftEdgeImagePosition = Math.abs(pointerCoords.x - imagePosition.x) <= padding;
    const rightEdgeImagePosition = Math.abs(pointerCoords.x - (imagePosition.x + imagePosition.width)) <= padding;
    const topEdgeImagePosition = Math.abs(pointerCoords.y - imagePosition.y) <= padding;
    const bottomEdgeImagePosition = Math.abs(pointerCoords.y - (imagePosition.y + imagePosition.height)) <= padding;

    const topLeftCornerSelectedArea = leftEdgeSelectedArea && topEdgeSelectedArea;
    const topRightCornerSelectedArea = rightEdgeSelectedArea && topEdgeSelectedArea;
    const bottomLeftCornerSelectedArea = leftEdgeSelectedArea && bottomEdgeSelectedArea;
    const bottomRightCornerSelectedArea = rightEdgeSelectedArea && bottomEdgeSelectedArea;

    const topLeftCornerImagePosition = leftEdgeImagePosition && topEdgeImagePosition;
    const topRightCornerImagePosition = rightEdgeImagePosition && topEdgeImagePosition;
    const bottomLeftCornerImagePosition = leftEdgeImagePosition && bottomEdgeImagePosition;
    const bottomRightCornerImagePosition = rightEdgeImagePosition && bottomEdgeImagePosition;

    if (
        leftEdgeSelectedArea ||
        rightEdgeSelectedArea ||
        topEdgeSelectedArea ||
        bottomEdgeSelectedArea ||
        topLeftCornerSelectedArea ||
        topRightCornerSelectedArea ||
        bottomLeftCornerSelectedArea ||
        bottomRightCornerSelectedArea
    ) {
        let edge = '';
        if (leftEdgeSelectedArea) edge = 'left';
        if (rightEdgeSelectedArea) edge = 'right';
        if (topEdgeSelectedArea) edge = 'top';
        if (bottomEdgeSelectedArea) edge = 'bottom';
        if (topLeftCornerSelectedArea) edge = 'topLeft';
        if (topRightCornerSelectedArea) edge = 'topRight';
        if (bottomLeftCornerSelectedArea) edge = 'bottomLeft';
        if (bottomRightCornerSelectedArea) edge = 'bottomRight';
        return { isOnEdge: true, edge, layer: 'selection' };
    } else if (
        leftEdgeImagePosition ||
        rightEdgeImagePosition ||
        topEdgeImagePosition ||
        bottomEdgeImagePosition ||
        topLeftCornerImagePosition ||
        topRightCornerImagePosition ||
        bottomLeftCornerImagePosition ||
        bottomRightCornerImagePosition
    ) {
        let edge = '';
        if (leftEdgeImagePosition) edge = 'left';
        if (rightEdgeImagePosition) edge = 'right';
        if (topEdgeImagePosition) edge = 'top';
        if (bottomEdgeImagePosition) edge = 'bottom';
        if (topLeftCornerImagePosition) edge = 'topLeft';
        if (topRightCornerImagePosition) edge = 'topRight';
        if (bottomLeftCornerImagePosition) edge = 'bottomLeft';
        if (bottomRightCornerImagePosition) edge = 'bottomRight';
        return { isOnEdge: true, edge, layer: 'image' };
    } else {
        return { isOnEdge: false, edge: '', layer: '' };
    }
};

const handleResizing = (
    mouseCoords: Point,
    resizeState: ResizeState,
    imagePosition: ImagePosition,
    selectedArea: SelectedArea,
    maxOutputWidth: number,
    maxOutputHeight: number
) => {
    // Calculate change in mouse position
    const dx = mouseCoords.x - resizeState.startMousePos.x;
    let dy = mouseCoords.y - resizeState.startMousePos.y;

    // Calculate new dimensions based on the resize direction
    let newWidth = resizeState.startPos.width;
    let newHeight = resizeState.startPos.height;
    let newX = resizeState.startPos.x;
    let newY = resizeState.startPos.y;

    // Make sure selection won't be smaller then image, make sureimg is never smaller then 10px
    const minWidth = 10;
    const minHeight = 10;

    // Calculate the aspect ratio
    const aspectRatio = newWidth / newHeight;

    // Adjust the input coords
    switch (resizeState.resizeDirection) {
        case 'left':
            ({ newWidth, newX } = adjustLeft(dx, minWidth, newWidth, newX));
            break;
        case 'right':
            ({ newWidth } = adjustRight(dx, newWidth));
            break;
        case 'top':
            ({ newHeight, newY } = adjustTop(dy, minHeight, newHeight, newY));
            break;
        case 'bottom':
            ({ newHeight } = adjustBottom(dy, minHeight, newHeight));
            break;
        case 'topLeft':
            // If the image corner is being dragged, lock the aspect ratio. Else, add delta to axis
            dy = resizeState.layer === 'image' ? dx / aspectRatio : dy;

            ({ newHeight, newY } = adjustTop(dy, minHeight, newHeight, newY));
            ({ newWidth, newX } = adjustLeft(dx, minWidth, newWidth, newX));
            break;
        case 'bottomRight':
            // If the image corner is being dragged, lock the aspect ratio. Else, add delta to axis
            dy = resizeState.layer === 'image' ? dx / aspectRatio : dy;

            ({ newHeight } = adjustBottom(dy, minHeight, newHeight));
            ({ newWidth } = adjustRight(dx, newWidth));
            break;
        case 'bottomLeft':
            // If the image corner is being dragged, lock the aspect ratio. Else, add delta to axis
            dy = resizeState.layer === 'image' ? -dx / aspectRatio : dy;

            ({ newHeight } = adjustBottom(dy, minHeight, newHeight));
            ({ newWidth, newX } = adjustLeft(dx, minWidth, newWidth, newX));
            break;
        case 'topRight':
            // If the image corner is being dragged, lock the aspect ratio. Else, add delta to axis
            dy = resizeState.layer === 'image' ? -dx / aspectRatio : dy;

            ({ newHeight, newY } = adjustTop(dy, minHeight, newHeight, newY));
            ({ newWidth } = adjustRight(dx, newWidth));
            break;
    }

    // Handle selection adjustments
    if (resizeState.layer === 'selection') {
        // Make sure new value does not exceed max output
        const maxConstrainedWidth = Math.min(newWidth, maxOutputWidth);
        const maxConstrainedHeight = Math.min(newHeight, maxOutputHeight);

        // If the max output width is hit, set x to max value to make sure the borders can be hit
        if (
            maxConstrainedWidth === maxOutputWidth &&
            (resizeState.resizeDirection === 'left' || resizeState.resizeDirection === 'topLeft' || resizeState.resizeDirection === 'bottomLeft')
        ) {
            // Calc max possible value
            const differenceInX = maxOutputWidth - newWidth;
            // Set x to max
            newX = Math.round(newX - differenceInX);
        }

        // If the max output height is hit, set y to max value to make sure the borders can be hit
        if (
            maxConstrainedHeight === maxOutputHeight &&
            (resizeState.resizeDirection === 'top' || resizeState.resizeDirection === 'topLeft' || resizeState.resizeDirection === 'topRight')
        ) {
            // Calc max possible value
            const differenceInY = maxOutputHeight - newHeight;
            // Set Y to the max value
            newY = Math.round(newY - differenceInY);
        }

        // Calculate the amaount of pixels to extend (outpaint), first set current values.
        let extendLeft = imagePosition.extendLeft;
        let extendUp = imagePosition.extendUp;
        let newImageX = imagePosition.x;
        let newImageY = imagePosition.y;

        // If the left selection border is moved adjust the extend left value and image position
        if (resizeState.resizeDirection === 'left' || resizeState.resizeDirection === 'topLeft' || resizeState.resizeDirection === 'bottomLeft') {
            // Calc new distance to the Left border of selection
            extendLeft = Math.round(extendLeft - (newX - selectedArea.x));
            // calc new image X relative to selection
            newImageX = newX + extendLeft;
        }

        // if the top is moved adjust the extend up value
        if (resizeState.resizeDirection === 'top' || resizeState.resizeDirection === 'topLeft' || resizeState.resizeDirection === 'topRight') {
            // Calc new distance to the top border of selection
            extendUp = Math.round(extendUp - (newY - selectedArea.y));
            // calc new image Y relative to selection
            newImageY = newY + extendUp;
        }

        // Calc the amount of pixels to expand down (outpaint)
        const extendDown = Math.round(selectedArea.y + selectedArea.height - (newImageY + imagePosition.height));
        const extendRight = Math.round(selectedArea.x + selectedArea.width - (newImageX + imagePosition.width));

        // Format the resized results
        const resizedValues = {
            selectedArea: {
                ...selectedArea,
                x: Math.round(newX),
                y: Math.round(newY),
                width: Math.round(maxConstrainedWidth),
                height: Math.round(maxConstrainedHeight)
            },
            imagePosition: {
                ...imagePosition,
                extendLeft,
                extendUp,
                extendDown,
                extendRight,
                x: newImageX,
                y: newImageY
            }
        };

        return resizedValues;
    }

    if (resizeState.layer === 'image') {
        // Format the resized values
        const resizedValues = {
            selectedArea: {
                ...selectedArea
            },
            imagePosition: {
                x: newX,
                y: newY,
                width: newWidth,
                height: newHeight,
                extendLeft: Math.round(newX - selectedArea.x),
                extendUp: Math.round(newY - selectedArea.y),
                extendDown: Math.round(selectedArea.y + selectedArea.height - (newY + newHeight)),
                extendRight: Math.round(selectedArea.x + selectedArea.width - (newX + newWidth))
            }
        };

        // Update selectedArea state for real-time feedback
        return resizedValues;
    }
};

// Handle dragging layers
const handleDragging = (mouseCoords: Point, dragState: DragState, imagePosition: ImagePosition, selectedArea: SelectedArea): ImagePosition => {
    const dx = mouseCoords.x - dragState.startMousePos.x;
    const dy = mouseCoords.y - dragState.startMousePos.y;

    // Start by calculating the intended new position without constraints
    const newPos = {
        x: dragState.startPos.x + dx,
        y: dragState.startPos.y + dy
    };

    // calc the coords of the corners
    const cornerPos = {
        topLeft: { x: imagePosition.x, y: imagePosition.y },
        topRight: { x: imagePosition.x + imagePosition.width, y: imagePosition.y },
        bottomLeft: { x: imagePosition.x, y: imagePosition.y + imagePosition.height },
        bottomRight: { x: imagePosition.x + imagePosition.width, y: imagePosition.y + imagePosition.height }
    };

    // Adjust the position to ensure at least one corner remains within the selected area
    if (cornerPos.topLeft.x > selectedArea.x + selectedArea.width) {
        newPos.x = selectedArea.x + selectedArea.width;
    }
    if (cornerPos.topRight.x < selectedArea.x) {
        newPos.x = selectedArea.x;
    }
    if (cornerPos.topLeft.y > selectedArea.y + selectedArea.height) {
        newPos.y = selectedArea.y + selectedArea.height;
    }
    if (cornerPos.bottomLeft.y < selectedArea.y) {
        newPos.y = selectedArea.y;
    }

    // Clamp the intended position to ensure the image stays within the draggable area
    newPos.x = Math.max(Math.min(newPos.x, selectedArea.x + selectedArea.width), selectedArea.x - imagePosition.width);
    newPos.y = Math.max(Math.min(newPos.y, selectedArea.y + selectedArea.height), selectedArea.y - imagePosition.height);

    // If selected layer === 'image' (always the case for now)
    const extendData = {
        extendLeft: Math.round(newPos.x - selectedArea.x),
        extendUp: Math.round(newPos.y - selectedArea.y),
        extendDown: Math.round(selectedArea.y + selectedArea.height - (newPos.y + imagePosition.height)),
        extendRight: Math.round(selectedArea.x + selectedArea.width - (newPos.x + imagePosition.width))
    };

    const newImagePosition: ImagePosition = {
        ...imagePosition,
        ...newPos,
        ...extendData
    };

    // Update imagePosition state for real-time feedback
    return newImagePosition;
};

const detectUserAction = (
    event: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
    mouseCoords: Point,
    dragState: DragState,
    resizeState: ResizeState,
    canvas: HTMLCanvasElement,
    selectedArea: SelectedArea,
    imagePosition: ImagePosition,
    translation: Point,
    zoomLevel: number
) => {
    const isPointerInSelectedAreaResult = isPointerInSelectedArea(mouseCoords, imagePosition, selectedArea, 4 / zoomLevel);
    const isPointerOnSelectedAreaEdgeResult = isPointerOnSelectedAreaEdge(mouseCoords, imagePosition, selectedArea, 4 / zoomLevel);

    // If the pointer is in the selected area but not on the edge
    if (isPointerInSelectedAreaResult.isInSelectedArea && !isPointerOnSelectedAreaEdgeResult.isOnEdge) {
        if (!dragState.isDragging && selectedArea && imagePosition) {
            return {
                isDragging: true,
                startMousePos: mouseCoords,
                startPos:
                    isPointerInSelectedAreaResult.layer === 'selection' ? { x: selectedArea.x, y: selectedArea.y } : { x: imagePosition.x, y: imagePosition.y },
                layer: isPointerInSelectedAreaResult.layer
            };
        }
    }

    // If the pointer is on the selectedArea edge
    if (isPointerOnSelectedAreaEdgeResult.isOnEdge && imagePosition) {
        if (!resizeState.isResizing && selectedArea) {
            return {
                isResizing: true,
                startMousePos: mouseCoords,
                startPos: isPointerOnSelectedAreaEdgeResult.layer === 'selection' ? { ...selectedArea } : { ...imagePosition },
                resizeDirection: isPointerOnSelectedAreaEdgeResult.edge,
                layer: isPointerOnSelectedAreaEdgeResult.layer
            };
        }
    }

    // Handle panning canvas outside selected area or image to get hing back in view after zooming etc.
    if (canvas && !dragState.isDragging && !resizeState.isResizing) {
        const rect = canvas.getBoundingClientRect();
        return {
            isPanning: true,
            startMousePos: {
                x: event.clientX - rect.left,
                y: event.clientY - rect.top
            },
            startTranslation: { ...translation }
        };
    }
};

const calculateZoom = (contentWidth: number, contentHeight: number, canvasWidth: number, canvasHeight: number) => {
    // Calculate the zoom factors for width and height separately
    const zoomWidth = (canvasWidth / contentWidth) * 0.9;
    const zoomHeight = (canvasHeight / contentHeight) * 0.9;

    // Use the smaller zoom factor to ensure the entire content fits
    let zoom = Math.min(zoomWidth, zoomHeight);

    // Ensure zoom does not go below a certain threshold
    const minZoom = 0.05;
    if (zoom < minZoom) {
        zoom = minZoom;
    }

    return zoom;
};

// Get the mouse coords within the canvas
const getMouseCoordsRelativeToCanvas = (canvas: HTMLCanvasElement, mouseX: number, mouseY: number, zoomLevel: number): Point => {
    const rect = canvas.getBoundingClientRect();
    if (!rect) return { x: 0, y: 0 };

    const rawX = (mouseX - rect.left) / zoomLevel;
    const rawY = (mouseY - rect.top) / zoomLevel;
    const mouseCoords: Point = { x: rawX, y: rawY };
    return mouseCoords;
};

export {
    isPointerInSelectedArea,
    isPointerOnSelectedAreaEdge,
    handleResizing,
    handleDragging,
    detectUserAction,
    calculateZoom,
    getMouseCoordsRelativeToCanvas
};
