import React, { useEffect, useMemo, useRef, useState } from 'react';
import AssetEditorHelper from 'components/assets/AssetEditor/helpers/asset-editor-helper';
import AssetEditorState from 'components/assets/AssetEditor/interfaces/AssetEditorState';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import AssetEditorImage from 'components/assets/AssetEditor/components/AssetEditorImage';
import OverlayLoadingIndicatorWrapper from 'components/assets/AssetEditor/components/OverlayLoadingIndicator';
import Translation from 'components/data/Translation';
import AssetGalleryDialogState from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';

import '../styles/main.scss';

interface ImageTransformsPreviewState {
    maxOutputWidth: AssetGalleryDialogState['data']['assetData']['maxOutputWidth'];
    maxOutputHeight: AssetGalleryDialogState['data']['assetData']['maxOutputHeight'];
    config: AssetGalleryDialogState['config'];
    userCanCrop: AssetGalleryDialogState['conditionProps']['userCanCrop'];
    imageQuality: AssetGalleryDialogState['data']['assetData']['imageQuality'];
}

/**
 * This component is responsible for rendering the preview of an image asset in the AssetTransformsPreview component.
 * It uses the useComponentStore hook to access the AssetEditor and AssetGallery stores, and the AssetEditorHelper to manipulate the asset.
 * It also defines helper functions to handle image rotation and cropping.
 */
const ImageTransformsPreview = () => {
    const { originalAssetSrc, modifiedAssetSrc, imageCropperState, assetFlipperState } = useComponentStore<AssetEditorState>('AssetEditor', {
        fields: {
            originalAssetSrc: 'originalAssetSrc',
            modifiedAssetSrc: 'modifiedAssetSrc',
            imageCropperState: 'imageCropperState',
            assetFlipperState: 'assetFlipperState'
        }
    });

    const { maxOutputHeight, maxOutputWidth, config, userCanCrop, imageQuality } = useComponentStore<ImageTransformsPreviewState>('AssetGalleryDialog', {
        fields: {
            maxOutputHeight: 'data.assetData.maxOutputHeight',
            maxOutputWidth: 'data.assetData.maxOutputWidth',
            config: 'config',
            userCanCrop: 'conditionProps.userCanCrop',
            imageQuality: 'data.assetData.imageQuality'
        }
    });

    const noneCroppedAssetSrc = AssetEditorHelper.getAssetUrl(originalAssetSrc, modifiedAssetSrc);
    const [assetSrc, setAssetSrc] = useState<string | undefined>(undefined);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const noneCroppedImageRef = useRef<HTMLImageElement>(null);
    const isCropping = useRef(false);

    /**
     * Rotates an image by the specified angle.
     * @param {HTMLImageElement} image - The image to rotate.
     * @param {number} angle - The rotation angle in degrees (clockwise).
     * @returns {HTMLCanvasElement} - The rotated image as a canvas element.
     */
    const handleImageRotation = async (image: HTMLImageElement, angle: number, scaleX: number, scaleY: number) => {
        const rotatedImage = document.createElement('canvas');
        rotatedImage.width = image.naturalWidth;
        rotatedImage.height = image.naturalHeight;
        const ctx = rotatedImage.getContext('2d');

        if (!ctx) {
            return image;
        }

        ctx.clearRect(0, 0, rotatedImage.width, rotatedImage.height);
        ctx.translate(rotatedImage.width / 2, rotatedImage.height / 2);
        ctx.rotate(angle * (Math.PI / 180));
        ctx.scale(scaleX, scaleY); // Apply scaling for flipping
        ctx.drawImage(image, -rotatedImage.width / 2, -rotatedImage.height / 2, rotatedImage.width, rotatedImage.height);

        const blob = await AssetEditorHelper.getBlobFromCanvas(rotatedImage, image.src, imageQuality); // Get the blob from the canvas.

        if (!blob) {
            return image;
        }

        const dataUrl = await AssetEditorHelper.convertBlobToBase64(blob); // Convert the blob to base64.
        image.src = dataUrl; // Set the image src to the cropped image.

        return image;
    };

    const handlePreview = async () => {
        try {
            if (!noneCroppedAssetSrc) {
                throw new Error(); // Show an error message if the asset src is not available.
            }

            let imageElement: HTMLImageElement = await AssetEditorHelper.getImageElement(noneCroppedAssetSrc);

            // rotation angle can be 0.
            if (assetFlipperState?.rotationAngle !== undefined) {
                const scaleX = assetFlipperState?.scaleX ? assetFlipperState.scaleX : 1;
                const scaleY = assetFlipperState?.scaleY ? assetFlipperState.scaleY : 1;

                imageElement = await handleImageRotation(imageElement, assetFlipperState?.rotationAngle, scaleX, scaleY); // Handle rotation.
            }

            if (userCanCrop && imageCropperState?.cropData) {
                if (!isCropping.current) {
                    isCropping.current = true; // Prevents the handleImageCropping function from being called multiple times.
                    imageElement = await handleImageCropping(imageElement); // Handle cropping.
                    isCropping.current = false; // Resets the isCropping state.
                }
            }

            const imageSrc = imageElement.src;

            const assetEditorState: AssetEditorState | undefined = ComponentStoreHelpers.get('AssetEditor');

            if (assetEditorState) {
                ComponentStoreHelpers.setModel('AssetEditor', 'croppedModifiedAssetSrc', imageSrc); // Sets the final modified asset src.
                setAssetSrc(imageSrc);
            }
        } catch (error) {
            SnackbarUtils.error(Translation.get('feedback.errors.oops', 'common'));
        }
    };

    /**
     * Handles the image cropping.
     */
    const handleImageCropping = async (imageElement: HTMLImageElement) => {
        const cropDataCopy = imageCropperState?.cropData ? { ...imageCropperState?.cropData } : null;

        if (!cropDataCopy) {
            return imageElement;
        }

        const contentImage: HTMLImageElement | null = noneCroppedImageRef?.current;

        if (!contentImage) {
            return imageElement;
        }

        const updatedWidth = contentImage.clientWidth;
        const updatedHeight = contentImage.clientHeight;
        const canvas = document.createElement('canvas');
        const scaleX = imageElement.naturalWidth / updatedWidth;
        const scaleY = imageElement.naturalHeight / updatedHeight;
        if (cropDataCopy.unit === '%') {
            cropDataCopy.width = (cropDataCopy.width / 100) * updatedWidth;
            cropDataCopy.height = (cropDataCopy.height / 100) * updatedHeight;
            cropDataCopy.x = (cropDataCopy.x / 100) * updatedWidth;
            cropDataCopy.y = (cropDataCopy.y / 100) * updatedHeight;
            cropDataCopy.unit = 'px';
        }
        const sx = cropDataCopy.x * scaleX,
            sy = cropDataCopy.y * scaleY,
            sWidth = cropDataCopy.width * scaleX,
            sHeight = cropDataCopy.height * scaleY;
        let outputWidth = sWidth;
        let outputHeight = sHeight;
        const imageAspect = imageElement.naturalWidth / imageElement.naturalHeight;
        //Calculate the size of the crop
        if (config.cropper.size.locked) {
            // Locked aspect ratio scenario.
            if (config.cropper.size.height === 0 && config.cropper.size.width === 0 && maxOutputHeight === 0 && maxOutputWidth === 0) {
                // No sizes are given, use image ratio.
                config.cropper.size.fixed = false;
            } else if (config.cropper.size.height === 0 && maxOutputHeight === 0) {
                // Only width is given, use this to determine height based on the image aspect ratio.
                config.cropper.size.height = config.cropper.size.width / imageAspect;
                config.cropper.size.fixed = true;
            } else if (config.cropper.size.width === 0 && maxOutputWidth === 0) {
                // Only height is given, use this to determine width based on the image aspect ratio.
                config.cropper.size.width = config.cropper.size.height * imageAspect;
                config.cropper.size.fixed = true;
            } else {
                // Normal scenario, aspect ratio is set based on specified width and height.
                if (maxOutputWidth && maxOutputHeight) {
                    config.cropper.size.fixed = false;
                } else {
                    config.cropper.size.fixed = true;
                }
            }
        } else {
            // Free aspect ratio scenario.
            if (config.cropper.size.width !== 0 || config.cropper.size.height !== 0) {
                // If a width is set, use fixed size. Derive the fixed hight from the crop aspect ratio.
                config.cropper.size.fixed = true;
            }
        }
        //Calculate the size of the output
        if (config.cropper.size.fixed) {
            if (config.cropper.size.locked) {
                // If aspect ratio is locked, we already know both sizes.
                outputWidth = config.cropper.size.width;
                outputHeight = config.cropper.size.height;
            } else {
                if (config.cropper.size.width > 0) {
                    outputWidth = config.cropper.size.width;
                    outputHeight = (config.cropper.size.width / cropDataCopy.width) * cropDataCopy.height;
                } else if (config.cropper.size.height > 0) {
                    outputHeight = config.cropper.size.height;
                    outputWidth = (config.cropper.size.height / cropDataCopy.height) * cropDataCopy.width;
                }
            }
        }
        const outputAspect = outputWidth / outputHeight;
        //If there is max output height or width check and scale both width and height accordingly capped at lowest value
        const bothMax = maxOutputHeight && maxOutputWidth ? true : false;
        if (((bothMax && outputWidth <= outputHeight) || (!bothMax && maxOutputHeight > 0)) && outputHeight > maxOutputHeight) {
            canvas.height = maxOutputHeight;
            canvas.width = maxOutputHeight * outputAspect;
            outputHeight = maxOutputHeight;
            outputWidth = maxOutputHeight * outputAspect;
        } else if (((bothMax && outputHeight < outputWidth) || (!bothMax && maxOutputWidth > 0)) && outputWidth > maxOutputWidth) {
            canvas.height = maxOutputWidth / outputAspect;
            canvas.width = maxOutputWidth;
            outputHeight = maxOutputWidth / outputAspect;
            outputWidth = maxOutputWidth;
        } else {
            canvas.height = outputHeight;
            canvas.width = outputWidth;
        }

        const ctx = canvas.getContext('2d');

        if (!ctx) {
            return imageElement;
        }

        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';
        ctx.drawImage(imageElement, sx, sy, sWidth, sHeight, 0, 0, outputWidth, outputHeight);

        const blob = await AssetEditorHelper.getBlobFromCanvas(canvas, imageElement.src, imageQuality); // Get the blob from the canvas.

        if (!blob) {
            return imageElement;
        }

        const dataUrl = await AssetEditorHelper.convertBlobToBase64(blob); // Convert the blob to base64.
        imageElement.src = dataUrl; // Set the image src to the cropped image.

        return imageElement;
    };

    /**
     * Sets default values for the AssetPreview component.
     * This function is called in the useEffect hook when the component mounts.
     */
    const setDefaultValues = () => {
        ComponentStoreHelpers.setModel('AssetEditor', 'previewAssetSrc', ''); // Resets asset preview src.
    };

    /**
     * Callback function that is called when the image finishes loading.
     * It calls the handlePreview function and sets the isImageLoaded state to true.
     */
    const handleOnImageLoad = async () => {
        if (!isImageLoaded) {
            await handlePreview(); // Handle preview when the background image element is loaded.
        }
    };

    /**
     * Sets the state of isImageLoaded to true when the modified image is loaded.
     */
    const handleOnModifiedImageLoad = () => {
        setIsImageLoaded(true); // Set the isImageLoaded state to true when the modified image is loaded.
    };

    useEffect(() => {
        setDefaultValues();

        return () => {
            const assetEditor: AssetEditorState | undefined = ComponentStoreHelpers.get('AssetEditor');

            if (assetEditor && assetEditor.loading) {
                ComponentStoreHelpers.setModel('AssetEditor', 'loading', false); // Set loading to false.
            }
        };
    }, []);

    useEffect(() => {
        if (!isImageLoaded) {
            ComponentStoreHelpers.setModel('AssetEditor', 'loading', true); // Set loading to true.
        } else {
            ComponentStoreHelpers.setModel('AssetEditor', 'loading', false); // Set loading to false.
        }
    }, [isImageLoaded]);

    useMemo(() => {
        if (isImageLoaded) {
            handlePreview(); // Handle preview only when the restore button is clicked.
        }
    }, [imageCropperState?.cropData, modifiedAssetSrc, assetFlipperState?.rotationAngle, assetFlipperState?.scaleX, assetFlipperState?.scaleY]);

    return (
        <OverlayLoadingIndicatorWrapper
            className="loading-overlay"
            text={Translation.get('assetGalleryDialog.assetEditor.loadingPreview', 'content-space')}
            isLoading={!isImageLoaded}>
            <AssetEditorImage
                mainDivClassName="loading-overlay__image-box"
                imageSrc={noneCroppedAssetSrc}
                imageBoxStyle={{ transform: `rotate(0deg) scaleX(1) scaleY(1)` }}
                onLoad={() => handleOnImageLoad()}
                imageClassName="loading-overlay__image-box__image-hidden"
                imageRef={noneCroppedImageRef}
            />

            <AssetEditorImage
                mainDivClassName="loading-overlay__image-box"
                imageSrc={assetSrc}
                imageBoxStyle={{ transform: `rotate(0deg) scaleX(1) scaleY(1)` }}
                onLoad={() => handleOnModifiedImageLoad()}
            />
        </OverlayLoadingIndicatorWrapper>
    );
};

export default ImageTransformsPreview;
