import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import Slider from 'components/ui-components-v2/Slider';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import Translation from 'components/data/Translation';
import ToggleButtonGroup from 'components/ui-components-v2/ToggleGroup';
import ToggleButton from 'components/ui-components-v2/ToggleButton';
import AssetMaskEditorState from '../interface/mask-editor-state';
import { DrawingMode } from './fabric-canvas';
import assetMaskEditorToggleBtn from '../data/asset-mask-editor-toggle-btn';

import '../styles/asset-magic-eraser-select-mode.scss';

/**
 * Renders the Asset Mask Editor mode component.
 */
const AssetMaskEditorMode: React.FC = () => {
    const { drawingMode, brushSize } = useComponentStore<AssetMaskEditorState>('AssetMaskEditor', {
        fields: { drawingMode: 'drawingMode', brushSize: 'brushSize' }
    });

    const handleSliderBrushSize = (event) => {
        const brushSize = parseInt(event.target.value);
        ComponentStoreHelpers.setModel('AssetMaskEditor', 'brushSize', brushSize);
    };

    const onDrawingModeChange = (mode: DrawingMode | null): void => {
        if (mode === null) {
            return;
        }

        ComponentStoreHelpers.setModel('AssetMaskEditor', 'drawingMode', mode);
    };

    return (
        <div className="select-mode">
            <ToggleButtonGroup
                value={drawingMode}
                color="primary"
                fullWidth
                exclusive
                onChange={(event, value) => {
                    onDrawingModeChange(value);
                }}>
                {assetMaskEditorToggleBtn.map((item, index) => {
                    return (
                        <ToggleButton key={index} value={item.value}>
                            <Icon className="select-mode__toggle-icon">{item.icon}</Icon>
                            {item.text}
                        </ToggleButton>
                    );
                })}
            </ToggleButtonGroup>
            {drawingMode === 'brush' && (
                <div className="select-mode__brush">
                    <div className="select-mode__brush__summary">
                        <div className="select-mode__brush__summary__text">{Translation.get('assetGalleryDialog.assetEditor.brushSize', 'content-space')}</div>
                        <div className="select-mode__brush__summary__text select-mode__brush__summary__size">{brushSize}</div>
                    </div>

                    <Slider className="select-mode__brush__slider" min={1} max={100} step={1} value={brushSize} onChange={handleSliderBrushSize} />
                </div>
            )}
        </div>
    );
};

export default AssetMaskEditorMode;
