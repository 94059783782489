import Translation from 'components/data/Translation';
import { ToggleButtonProps } from 'components/ui-components/ToggleButtonGroup';

/**
 * An array of objects representing toggle buttons for the asset mask editor.
 * Each object contains an icon, text, and value property.
 */
const assetMaskEditorToggleBtn: ToggleButtonProps[] = [
    {
        icon: 'brush',
        text: Translation.get('assetGalleryDialog.assetEditor.brush', 'content-space'),
        value: 'brush'
    },
    {
        icon: 'crop_free',
        text: Translation.get('assetGalleryDialog.assetEditor.frame', 'content-space'),
        value: 'rectangle'
    }
];

export default assetMaskEditorToggleBtn;
