import React from 'react';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import AssetHelper from 'helpers/asset.helper';
import ImageTransformsPreview from './components/image-transforms-preview';
import VideoTransformsPreview from './components/video-transforms-preview';

import './styles/main.scss';

interface AssetTransformsPreviewState {
    fileType: string | string[];
    extension: string;
}

/**
 * Renders a preview of an asset based on its file type.
 * If the file type is video, it renders a VideoPreview component.
 * If the file type is an image, it renders an ImagePreview component.
 */
const AssetTransformsPreview: React.FC = () => {
    const { extension } = useComponentStore<AssetTransformsPreviewState>('AssetGalleryDialog', {
        fields: { extension: 'value.extension' }
    });

    return AssetHelper.getFileType(extension) === 'video' ? <VideoTransformsPreview /> : <ImageTransformsPreview />;
};

export default AssetTransformsPreview;
