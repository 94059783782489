import { useLayoutEffect } from 'react';
import { SelectedArea, ImagePosition, Point, OutpaintCanvasOutput } from '../interfaces/outpaint';
import { calculateZoom } from '../helpers/outpaint-canvas-helpers';

const useCanvasDraw = (
    context: CanvasRenderingContext2D | null,
    imageElement: HTMLImageElement | null,
    selectedArea: SelectedArea | null,
    imagePosition: ImagePosition | null,
    canvasWidth: number,
    canvasHeight: number,
    zoomLevel: number,
    translation: Point,
    processedMaxOutputWidth: number,
    processedMaxOutputHeight: number,
    ratio: number,
    setZoomLevel: React.Dispatch<React.SetStateAction<number>>,
    setSelectedArea: React.Dispatch<React.SetStateAction<SelectedArea | null>>,
    setImagePosition: React.Dispatch<React.SetStateAction<ImagePosition | null>>,
    onChange: (asset: OutpaintCanvasOutput) => void
) => {
    useLayoutEffect(() => {
        drawCanvas();
    }, [
        context,
        imageElement,
        selectedArea,
        imagePosition,
        canvasWidth,
        canvasHeight,
        zoomLevel,
        translation,
        processedMaxOutputWidth,
        processedMaxOutputHeight,
        ratio,
        setZoomLevel,
        setSelectedArea,
        setImagePosition,
        onChange
    ]);

    // Draw the elements on the canvas
    const drawCanvas = () => {
        if (context && imageElement) {
            context.resetTransform();
            context.clearRect(0, 0, canvasWidth, canvasHeight);

            context.translate(translation.x, translation.y);
            context.scale(zoomLevel, zoomLevel);
            context.translate(-translation.x, -translation.y);

            context.strokeStyle = '#2971EB';
            context.lineWidth = 1 / zoomLevel;

            // If there is no selected area create one
            if (!selectedArea) {
                const selectedAreaWidth = Math.min(Math.round(imageElement.naturalWidth * 2), processedMaxOutputWidth);
                const selectedAreaHeight = Math.min(Math.round(imageElement.naturalHeight * 2), processedMaxOutputHeight);

                const zoom = calculateZoom(selectedAreaWidth, selectedAreaHeight, canvasWidth, canvasHeight);
                setZoomLevel(zoom);

                const canvasCenterX = canvasWidth / 2 / zoom;
                const canvasCenterY = canvasHeight / 2 / zoom;

                const selectedAreaX = canvasCenterX - selectedAreaWidth / 2;
                const selectedAreaY = canvasCenterY - selectedAreaHeight / 2;

                setSelectedArea({
                    x: selectedAreaX,
                    y: selectedAreaY,
                    width: selectedAreaWidth,
                    height: selectedAreaHeight
                });
            }

            // If there is a selectedArea but no image position, fit the image inside the selected area
            if (selectedArea && !imagePosition) {
                const naturalWidth = imageElement.naturalWidth;
                const naturalHeight = imageElement.naturalHeight;

                let finalWidth = naturalWidth;
                let finalHeight = naturalHeight;

                // Only scale the image if it exceeds the selected area
                if (naturalWidth > selectedArea.width || naturalHeight > selectedArea.height) {
                    const widthRatio = selectedArea.width / naturalWidth;
                    const heightRatio = selectedArea.height / naturalHeight;
                    const scaleRatio = Math.min(widthRatio, heightRatio);

                    finalWidth = naturalWidth * scaleRatio;
                    finalHeight = naturalHeight * scaleRatio;
                }

                // Center the image within the selected area)
                const imageX = selectedArea.x + (selectedArea.width - finalWidth) / 2;
                const imageY = selectedArea.y + (selectedArea.height - finalHeight) / 2;

                setImagePosition({
                    x: Math.round(imageX),
                    y: Math.round(imageY),
                    width: Math.round(finalWidth),
                    height: Math.round(finalHeight),
                    extendLeft: Math.round(imageX - selectedArea.x),
                    extendUp: Math.round(imageY - selectedArea.y),
                    extendDown: Math.round(selectedArea.height - (imageY - selectedArea.y + finalHeight)),
                    extendRight: Math.round(selectedArea.width - (imageX - selectedArea.x + finalWidth))
                });
            }

            // if there is a selected area and imagePosition. Update the positions
            if (imagePosition && selectedArea) {
                const imageX = selectedArea.x + imagePosition.extendLeft;
                const imageY = selectedArea.y + imagePosition.extendUp;
                context.drawImage(imageElement, imageX, imageY, imagePosition.width, imagePosition.height);

                // Handles and update outpaint data
                drawBorderAndDragHandles({ x: imageX, y: imageY, width: imagePosition.width, height: imagePosition.height }, '#D9D9D9');
                onChange({ selectedArea, imagePosition });
            }

            // Draw the handles
            if (selectedArea) {
                drawBorderAndDragHandles(selectedArea, '#2971EB');
            }
        }
    };

    // Draw the border and drag handles
    const drawBorderAndDragHandles = (area: { x: number; y: number; width: number; height: number }, color: string) => {
        if (!selectedArea || !context) return;

        const { x, y, width, height } = area;
        const handleSize = 4 / zoomLevel;
        const pillWidth = handleSize * 10;

        context.strokeStyle = color;
        context.strokeRect(x, y, width, height);

        context.fillStyle = color;

        const handles = [
            { x: x - handleSize / 2, y: y - handleSize / 2, width: handleSize, height: handleSize, type: 'circle' },
            { x: x + width / 2 - pillWidth / 2, y: y - handleSize / 2, width: pillWidth, height: handleSize, type: 'pill' },
            { x: x + width - handleSize / 2, y: y - handleSize / 2, width: handleSize, height: handleSize, type: 'circle' },
            { x: x - handleSize / 2, y: y + height / 2 - pillWidth / 2, width: handleSize, height: pillWidth, type: 'pill' },
            { x: x + width - handleSize / 2, y: y + height / 2 - pillWidth / 2, width: handleSize, height: pillWidth, type: 'pill' },
            { x: x - handleSize / 2, y: y + height - handleSize / 2, width: handleSize, height: handleSize, type: 'circle' },
            { x: x + width / 2 - pillWidth / 2, y: y + height - handleSize / 2, width: pillWidth, height: handleSize, type: 'pill' },
            { x: x + width - handleSize / 2, y: y + height - handleSize / 2, width: handleSize, height: handleSize, type: 'circle' }
        ];

        handles.forEach((handle) => {
            if (handle.type === 'circle') {
                context.beginPath();
                context.arc(handle.x + handleSize / 2, handle.y + handleSize / 2, handleSize, 0, Math.PI * 2);
                context.fill();
            } else if (handle.type === 'pill') {
                context.beginPath();
                if (handle.width > handle.height) {
                    const radius = handle.height / 2;
                    context.moveTo(handle.x + radius, handle.y);
                    context.lineTo(handle.x + handle.width - radius, handle.y);
                    context.arc(handle.x + handle.width - radius, handle.y + radius, radius, -Math.PI / 2, Math.PI / 2);
                    context.lineTo(handle.x + radius, handle.y + handle.height);
                    context.arc(handle.x + radius, handle.y + radius, radius, Math.PI / 2, -Math.PI / 2);
                } else {
                    const radius = handle.width / 2;
                    context.moveTo(handle.x, handle.y + radius);
                    context.lineTo(handle.x, handle.y + handle.height);
                    context.arc(handle.x + radius, handle.y + handle.height - radius, radius, Math.PI, 0, true);
                    context.lineTo(handle.x + handle.width, handle.y + radius);
                    context.arc(handle.x + radius, handle.y + radius, radius, 0, Math.PI, true);
                }
                context.closePath();
                context.fill();
            }
        });
    };
};

export default useCanvasDraw;
