import React, { Component } from 'react';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.js';
import PlayheadPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.playhead.min.js';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import './../styles/main.scss';
import cloneDeep from 'helpers/cloneDeep';
import Translation from '../../../data/Translation';

export class AudioTrimmer extends Component {
    constructor(props) {
        super(props);

        this.wavesurfer = null;

        //add a default selected region or load an existing one
        const selectedRegion = this.props.selectedAudio.selectedRegion
            ? {
                  ...this.props.selectedAudio.selectedRegion,
                  ...(this.props.minimumDuration && { minLength: this.props.minimumDuration }),
                  ...(this.props.maximumDuration && { maxLength: this.props.maximumDuration })
              }
            : {
                  id: 'selectedRegion',
                  test: true,
                  start: 0,
                  end: this.props.minimumDuration || this.props.duration || 3,
                  resize: this.props.duration && !this.props.minimumDuration && !this.props.maximumDuration ? false : true,
                  ...(this.props.minimumDuration && { minLength: this.props.minimumDuration }),
                  ...(this.props.maximumDuration && { maxLength: this.props.maximumDuration })
              };

        this.state = {
            isPlaying: false,
            loading: true,
            keyPressInfo: {
                keyPressed: false,
                duration: this.props.duration ? this.props.duration : 0,
                initialTimeStamp: null
            },
            selectedAudio: {
                ...this.props.selectedAudio,
                selectedRegion: selectedRegion,
                isCropped: this.props.selectedAudio.isCropped ? this.props.selectedAudio.isCropped : false,
                audioDuration: '',
                originalUrl: this.props.selectedAudio.originalUrl ? this.props.selectedAudio.originalUrl : this.props.selectedAudio.url
            }
        };
    }

    componentDidMount = () => {
        const { onMutation } = this.props;

        // Initialize wavesurfer
        this.initializeWavesurfer();
        //set value to initial selection
        onMutation(this.state.selectedAudio);

        //add key event listeners
        window.addEventListener('keydown', (e) => this.handleKeyPress(e, true), false);
        window.addEventListener('keyup', (e) => this.handleKeyPress(e, false), false);
    };

    componentWillUnmount = () => {
        // Unsubscribe from all wavesurfer event listeners
        this.wavesurfer.unAll();
        this.wavesurfer.destroy();
    };

    componentDidUpdate = (prevProps, prevState) => {
        const { selectedAudio } = this.state;
        const { onMutation } = this.props;

        // Update the selected audio after a change
        if (prevState.selectedAudio !== selectedAudio) {
            onMutation(selectedAudio);
        }

        // Allow external updates
        if (prevProps.selectedAudio !== this.props.selectedAudio) {
            this.setState({ selectedAudio: this.props.selectedAudio });
        }

        // Set custom labels and handlers
        this.setRegionLabelsAndHandlers();
    };

    /**
     * Initialize wavesurfer
     */
    initializeWavesurfer = () => {
        const { selectedAudio } = this.state;

        //Create a wavesurfer instance with the following options
        this.wavesurfer = WaveSurfer.create({
            container: '#waveform',
            cursorColor: '#FF8800',
            barWidth: 2,
            barRadius: 4,
            barGap: 2,
            cursorWidth: 2,
            pixelRatio: 3,
            partialRender: true,
            responsive: true,
            scrollParent: false,
            fillParent: true,
            forceDecode: true,
            plugins: [
                RegionsPlugin.create({
                    regions: [],
                    snapToGridInterval: 0.01
                }),
                PlayheadPlugin.create({
                    returnOnPause: true,
                    moveOnSeek: true,
                    draw: true
                })
            ]
        });

        //Add wavesurfer event listeners
        this.wavesurfer.on('ready', this.onWaveSurferReady);
        this.wavesurfer.on('seek', this.onWaveSurferAudioProcess);
        this.wavesurfer.on('audioprocess', this.onWaveSurferAudioProcess);
        this.wavesurfer.on('interaction', () => this.wavesurfer.pause());
        this.wavesurfer.on('region-updated', (segment) => this.onUpdateSelectedAudio(segment));
        this.wavesurfer.on('region-update-end', (segment) => this.updatePlayhead(segment));
        this.wavesurfer.on('play', () => this.setState({ isPlaying: true }));
        this.wavesurfer.on('pause', () => this.setState({ isPlaying: false }));
        this.wavesurfer.on('finish', () => this.setState({ isPlaying: false }));

        //load audio
        this.wavesurfer.load(selectedAudio.originalUrl);
    };

    /**
     * Do when wavesurfer is ready
     */
    onWaveSurferReady = () => {
        const { setTimeData } = this.props;
        const { selectedAudio } = this.state;
        const { selectedRegion } = selectedAudio;

        // get the duration in HMS:ms format
        const duration = this.convertHMS(this.wavesurfer.getDuration());
        setTimeData({ timeElapsed: '00:00:00', totalDuration: duration });

        this.setState({ loading: false }, () => {
            //add region to wavesurfer and update it's handlers and labels + update playhead
            this.wavesurfer.regions.add(selectedRegion);
            this.wavesurfer.playhead.setPlayheadTime(selectedRegion.start);
            this.setRegionLabelsAndHandlers();
        });
    };

    /**
     * Update times while audio is playing
     */
    onWaveSurferAudioProcess = () => {
        const { timeData, setTimeData } = this.props;

        // get the elapsed time in HMS:ms format
        const timeElapsed = this.convertHMS(this.wavesurfer.getCurrentTime());
        setTimeData({ ...timeData, timeElapsed: timeElapsed });

        this.setState({ timeElapsed });
    };

    /**
     * Update the selected region
     * @param {Object} selectedRegion the updated selected region
     */
    onUpdateSelectedAudio = (selectedRegion) => {
        const { selectedAudio } = this.state;
        let selectedAudioClone = cloneDeep(selectedAudio);

        const updatedRegion = {
            id: selectedRegion.id,
            start: selectedRegion.start,
            end: selectedRegion.end,
            minLength: selectedRegion.minLength,
            maxLength: selectedRegion.maxLength
        };

        selectedAudioClone = { ...selectedAudioClone, selectedRegion: updatedRegion };

        this.setState({ selectedAudio: selectedAudioClone });
    };

    /**
     * Update the play head after a segment update
     * @param {Object} segment the updated segment
     */
    updatePlayhead = (segment) => {
        const { isPlaying } = this.state;

        // update playhead
        this.wavesurfer.playhead.setPlayheadTime(segment.start);

        // if the audio was playing continue on new segment start
        if (isPlaying) this.wavesurfer.play(segment.start, segment.end);
    };

    /**
     * Set and adjust the regions handlers and label styling
     */
    setRegionLabelsAndHandlers = () => {
        const { selectedAudio } = this.state;
        const { selectedRegion } = selectedAudio;

        if (!selectedRegion) return;

        const parentWaveElement = document.querySelector('wave');
        parentWaveElement.className = 'parent';

        const child = document.createElement('div');
        child.className = 'wavesurfer-region-label';

        const transcript = `${this.convertHMS(selectedRegion.end - selectedRegion.start)}`;
        const targetElement = document.querySelector('[data-id="selectedRegion"');

        if (targetElement) {
            targetElement.setAttribute('title', transcript);

            const hasChild = targetElement.querySelector('.wavesurfer-region-label') !== null;
            if (!hasChild) {
                child.innerHTML = `<p class="wavesurfer-region-label__transcript">${transcript}</p>`;
                targetElement.appendChild(child.cloneNode(true));
            } else {
                targetElement.querySelector('.wavesurfer-region-label__transcript').innerHTML = transcript;
            }
        }
    };

    /**
     *  Make sure the selected region is and stays valid while moving the handlers
     * @param {*} updatedSelectedRegion the adjusted selected region
     * @param {*} audioDuration The total audio duration
     * @param {*} desiredRegionLength The desired length of the region
     * @returns a valid region
     */
    ensureValidRegionLength = (updatedSelectedRegion, audioDuration, desiredRegionLength) => {
        if (desiredRegionLength) {
            // If the updated region start is 0, set the end to the desired length
            if (updatedSelectedRegion.start === 0) {
                if (updatedSelectedRegion.end - updatedSelectedRegion.start !== desiredRegionLength) {
                    updatedSelectedRegion.end = desiredRegionLength;
                }
            }

            // If the region end === audio duration. set the start to the end time - the minimum duration
            if (updatedSelectedRegion.end === audioDuration) {
                if (updatedSelectedRegion.end - updatedSelectedRegion.start !== desiredRegionLength) {
                    updatedSelectedRegion.start = updatedSelectedRegion.end - desiredRegionLength;
                }
            }
        }

        return updatedSelectedRegion;
    };

    /**
     * Handle key press (moving the segment)
     * @param {Object} e the key press event
     * @param {*} downPress true if the key is pressed down
     */
    handleKeyPress = (e, downPress) => {
        const { keyPressInfo, selectedAudio } = this.state;
        const { selectedRegion } = selectedAudio;

        if (e.code === 'ArrowLeft' || e.code === 'ArrowRight') {
            //if the key is pressed down
            if (downPress) {
                // get the duration how long the key has been pressed down
                const duration = keyPressInfo.initialTimeStamp ? e.timeStamp - keyPressInfo.initialTimeStamp : 0;
                const updatedKeyPressInfo = {
                    keyPressed: true,
                    duration: duration,
                    initialRegion: keyPressInfo.initialRegion ? keyPressInfo.initialRegion : selectedRegion,
                    initialTimeStamp: keyPressInfo.initialTimeStamp ? keyPressInfo.initialTimeStamp : e.timeStamp
                };
                const desiredRegionLength = updatedKeyPressInfo.initialRegion.end - updatedKeyPressInfo.initialRegion.start;

                // calculate the amount to move (increase when key is pressed for a while to accelerate seeking)
                let amountToIncrement = keyPressInfo.duration > 2000 ? keyPressInfo.duration / 100000 : 0.01;
                amountToIncrement = e.code === 'ArrowLeft' ? -amountToIncrement : amountToIncrement;

                //get the audio duration
                const audioDuration = this.wavesurfer.getDuration();

                // update the selected region
                const start = selectedRegion.start;
                const end = selectedRegion.end;

                let updatedStart = start + amountToIncrement;
                let updatedEnd = end + amountToIncrement;

                // Make sure start is 0 or higher
                updatedStart = updatedStart < 0 ? 0 : updatedStart;
                // Make sure region end does not exceed the audio duration
                updatedEnd = updatedEnd < audioDuration ? updatedEnd : audioDuration;

                let updatedSelectedRegion = {
                    ...selectedRegion,
                    start: updatedStart,
                    end: updatedEnd
                };

                // Make sure the updated region is the desired length
                updatedSelectedRegion = this.ensureValidRegionLength(updatedSelectedRegion, audioDuration, desiredRegionLength);

                // update the region on the waveform
                this.wavesurfer.regions.list['selectedRegion'].update(updatedSelectedRegion);

                // updated the selected audio
                const updatedSelectedAudio = { ...selectedAudio, selectedRegion: updatedSelectedRegion };
                this.setState({ keyPressInfo: updatedKeyPressInfo, selectedAudio: updatedSelectedAudio });
            }

            if (!downPress) {
                this.updatePlayhead(selectedRegion);
                this.setState({
                    keyPressInfo: { keyPressed: false, duration: 0, initialRegion: null, initialTimeStamp: null }
                });
            }
        }
    };

    /**
     * Convert time from seconds to HMS format
     * @param {Number} value The time to convert in seconds
     * @returns The time in HMS format
     */
    convertHMS = (value) => {
        const duration = value * 1000;
        const milliseconds = Math.round(((duration + Number.EPSILON) % 1000) / 10);

        let seconds = Math.floor((duration / 1000) % 60);
        let minutes = Math.floor((duration / (1000 * 60)) % 60);
        let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        return `${hours > 0 ? hours + ':' : ''}${minutes}:${seconds}:${milliseconds < 10 ? '0' : ''}${milliseconds}`;
    };

    /**
     * Play/pause the loaded audio file
     */
    onPlayPause = () => {
        const { isPlaying, selectedAudio } = this.state;

        const currentTime = this.wavesurfer.getCurrentTime();

        if (!isPlaying) {
            if (currentTime > selectedAudio.selectedRegion.start && currentTime < selectedAudio.selectedRegion.end) {
                this.wavesurfer.play(this.wavesurfer.getCurrentTime(), selectedAudio.selectedRegion.end);
            } else {
                this.wavesurfer.play(selectedAudio.selectedRegion.start, selectedAudio.selectedRegion.end);
            }
        }

        if (isPlaying) this.wavesurfer.pause();

        this.setState({ isPlaying: !isPlaying });
    };

    render() {
        const { timeData } = this.props;
        const { loading, isPlaying, selectedAudio } = this.state;

        return (
            <div className="audio-trimmer">
                <div className="audio-trimmer__wave">
                    <div className="audio-trimmer__wave__container">
                        <div id="waveform"></div>
                        <div id="wave-timeline"></div>
                    </div>
                </div>
                {!loading && timeData.totalDuration && (
                    <div className="audio-trimmer__controls">
                        <div className="audio-trimmer__controls__info">
                            <div className="audio-trimmer__controls__info__title">{selectedAudio.title}</div>
                            <div className="audio-trimmer__controls__info__subtitle">{`${Translation.get(
                                'assetGalleryDialog.assetAudioTrimmer.size',
                                'content-space'
                            )}: ${selectedAudio.humanSize ? selectedAudio.humanSize + ' | ' : ''} ${timeData.totalDuration} `}</div>
                        </div>
                        <div className="audio-trimmer__controls__play-icon">
                            {!isPlaying ? (
                                <PlayCircleOutlineIcon onClick={this.onPlayPause} fontSize="large" color="primary" />
                            ) : (
                                <PauseCircleOutlineIcon onClick={this.onPlayPause} fontSize="large" color="primary" />
                            )}
                        </div>
                        <div className="audio-trimmer__controls__times">
                            <div className="audio-trimmer__controls__times__text">Trim from:</div>
                            <div>{this.convertHMS(selectedAudio.selectedRegion?.start)}</div>
                            <div className="audio-trimmer__controls__times__text">to:</div>
                            <div>{this.convertHMS(selectedAudio.selectedRegion?.end)}</div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default AudioTrimmer;
