import React, { useEffect, useRef, useState } from 'react';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import Translation from 'components/data/Translation';
import AssetEditorImage from '../AssetEditor/components/AssetEditorImage';
import AssetMaskEditorState from './interface/mask-editor-state';
import OverlayLoadingIndicatorWrapper from '../AssetEditor/components/OverlayLoadingIndicator';
import AssetEditorState from '../AssetEditor/interfaces/AssetEditorState';
import AssetEditorHelper from '../AssetEditor/helpers/asset-editor-helper';
import FabricCanvas, { DrawingMode, FabricCanvasSize } from './components/fabric-canvas';
import './styles/main.scss';

/**
 * A component that allows users to remove objects from an image using a Object Remover tool.
 */
const AssetMaskEditor: React.FC = () => {
    const { originalAssetSrc, modifiedAssetSrc, previewAssetSrc, loading, componentKey } = useComponentStore<AssetEditorState>('AssetEditor', {
        fields: {
            originalAssetSrc: 'originalAssetSrc',
            modifiedAssetSrc: 'modifiedAssetSrc',
            assetFormat: 'assetFormat',
            previewAssetSrc: 'previewAssetSrc',
            loading: 'loading',
            componentKey: 'componentKey'
        }
    });

    const { isMaskEditDisabled, isResetTriggered, drawingMode, brushSize, isEditTriggered } = useComponentStore<AssetMaskEditorState>('AssetMaskEditor', {
        fields: {
            isMaskEditDisabled: 'isMaskEditDisabled',
            isResetTriggered: 'isResetTriggered',
            drawingMode: 'drawingMode',
            brushSize: 'brushSize',
            isEditTriggered: 'isEditTriggered'
        }
    });

    const imgRef = useRef<HTMLImageElement>(null);
    const brushSizeRef = useRef<number>(brushSize);
    const drawingModeRef = useRef<DrawingMode>(drawingMode);
    const assetSrc = AssetEditorHelper.getAssetUrl(originalAssetSrc, modifiedAssetSrc, previewAssetSrc);
    const brushColor = 'rgba(255, 3, 64, 0.4)';
    const [canvasSize, setCanvasSize] = useState<FabricCanvasSize>({ width: 200, height: 200 });

    const handleMouseDown = () => {
        ComponentStoreHelpers.setModel('AssetMaskEditor', 'isMaskEdited', true); // Set the mask edited to true
    };

    const resetModificationState = () => {
        ComponentStoreHelpers.setMultiModels('AssetMaskEditor', [
            ['isResetTriggered', false],
            ['isMaskEdited', false],
            ['previewAssetSrc', ''],
            ['maskAssetSrc', '']
        ]);
    };

    /**
     * Set canvas dimensions to match the image dimensions
     */
    const handleCanvasSize = () => {
        const imageElement = imgRef?.current;

        if (!imageElement) {
            return;
        }

        setCanvasSize({ width: imageElement.width, height: imageElement.height });
    };

    /**
     * Setup canvas dimensions and listeners.
     */
    const onSetupCanvas = () => {
        // Set canvas dimensions to match the image dimensions
        handleCanvasSize();

        // Resize canvas when the window is resized
        window.addEventListener('resize', handleCanvasSize);

        // Clean up event listeners when component unmounts
        return () => {
            window.removeEventListener('resize', handleCanvasSize);
        };
    };

    /**
     * Creates a base64 mask file and sets the new maskAssetSrc in the ComponentStore.
     * @param base64 - The base64 string of the mask file.
     */
    const createBase64MaskFile = (base64: string | undefined) => {
        // Set new maskAssetSrc
        ComponentStoreHelpers.setMultiModels('AssetMaskEditor', [
            ['maskAssetSrc', base64],
            ['isMaskAssetSrcReady', true]
        ]);
    };

    useEffect(() => {
        brushSizeRef.current = brushSize;
    }, [brushSize]);

    useEffect(() => {
        drawingModeRef.current = drawingMode;
    }, [drawingMode]);

    useEffect(() => {
        ComponentStoreHelpers.setData('AssetMaskEditor', { isMaskEdited: false, isResetTriggered: false, drawingMode: 'brush', brushSize: 20 });
    }, []);

    useEffect(() => {
        if (imgRef?.current) {
            handleCanvasSize();
            imgRef.current.onload = onSetupCanvas;
        }
    }, [imgRef?.current]);

    const getLoaderText = () => {
        switch (componentKey) {
            case 'objectRemover':
                return Translation.get('assetGalleryDialog.assetEditor.removingObjects', 'content-space');
            case 'magicEditor':
                return Translation.get('assetGalleryDialog.assetEditor.generating', 'content-space');
            default:
                return Translation.get('assetGalleryDialog.assetEditor.loading', 'content-space');
        }
    };

    return (
        <OverlayLoadingIndicatorWrapper text={getLoaderText()} isLoading={loading}>
            <AssetEditorImage imageSrc={assetSrc} imageRef={imgRef} imageBoxStyle={{ transform: `rotate(0deg) scaleX(1) scaleY(1)` }}>
                <div className="magic-eraser__canvas-box">
                    <FabricCanvas
                        className="magic-eraser__canvas-box__canvas"
                        drawingColor={brushColor}
                        brushSize={brushSize}
                        isDrawingEnabled={!isMaskEditDisabled}
                        drawingMode={drawingMode}
                        canvasSize={canvasSize}
                        canvasMask={{
                            createBase64MaskCallback: createBase64MaskFile,
                            enableCreateMaskCallback: isEditTriggered,
                            width: imgRef.current?.width,
                            height: imgRef.current?.height,
                            naturalWidth: imgRef.current?.naturalWidth,
                            naturalHeight: imgRef.current?.naturalHeight
                        }}
                        onMouseDown={handleMouseDown}
                        resetDrawings={isResetTriggered}
                        resetDrawingsCallback={resetModificationState}
                    />
                </div>
            </AssetEditorImage>
        </OverlayLoadingIndicatorWrapper>
    );
};

export default AssetMaskEditor;
