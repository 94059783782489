import React from 'react';
import VideoCropperWrapper from 'components/assets/AssetGalleryCropper/components/video-cropper-wrapper';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import AssetEditorState from 'components/assets/AssetEditor/interfaces/AssetEditorState';

/**
 * Renders a preview of a video asset with cropping functionality.
 * @returns JSX element containing the video preview.
 */
const VideoTransformsPreview = () => {
    const { croppedModifiedAssetSrc } = useComponentStore<AssetEditorState>('AssetEditor', {
        fields: {
            croppedModifiedAssetSrc: 'videoCropperState.croppedModifiedAssetSrc'
        }
    });

    return <VideoCropperWrapper croppedVideoUrl={croppedModifiedAssetSrc} showEditControls={false} />;
};

export default VideoTransformsPreview;
