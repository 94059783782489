/**
 * Get the max width and height of the output (selectedArea) keeping the aspect ratio in mind
 */
const getMaxWidthAndHeightBasedOnApectRatio = (
    _selectedRatio: string,
    outputHeight: number,
    outputWidth: number,
    maxOutputWidth?: number,
    maxOutputHeight?: number
) => {
    const absoluteMax = 2048;
    const maxPropWidth = Math.max(outputWidth, maxOutputWidth || 0);
    const maxPropHeight = Math.max(outputHeight, maxOutputHeight || 0);
    const processedMaxOutputWidth = maxPropWidth === 0 ? absoluteMax : Math.min(absoluteMax, maxPropWidth);
    const processedMaxOutputHeight = maxPropHeight === 0 ? absoluteMax : Math.min(absoluteMax, maxPropHeight);

    const result = {
        maxOutputWidth: processedMaxOutputWidth,
        maxOutputHeight: processedMaxOutputHeight
    };
    return result;
};

export { getMaxWidthAndHeightBasedOnApectRatio };
