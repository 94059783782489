import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import OverlayLoadingIndicatorWrapper from 'components/assets/AssetEditor/components/OverlayLoadingIndicator';
import AssetEditorImage from 'components/assets/AssetEditor/components/AssetEditorImage';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import AssetGalleryData from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryData';
import AssetEditorState from '../../AssetEditor/interfaces/AssetEditorState';
import { OutpaintState } from '../../AssetGalleryCropper/interfaces/asset-cropper-state';
import { getMaxWidthAndHeightBasedOnApectRatio } from '../helpers/outpaint-ratio-helpers';
import { SelectedArea, ImagePosition, Point, OutpaintCanvasOutput } from '../interfaces/outpaint';
import useCanvasDraw from '../hooks/useCanvasDraw';
import useEventListeners from '../hooks/useEventListeners';
import { calculateZoom } from '../helpers/outpaint-canvas-helpers';

interface Props {
    outputWidth: number;
    outputHeight: number;
    maxOutputWidth?: number;
    maxOutputHeight?: number;
    imageFormat?: string;
    imageQuality: number;
    image: AssetGalleryData;
    outpaintMode: string;
    onChange: (asset: OutpaintCanvasOutput) => void;
}

const OutpaintEditorCanvas: React.FC<Props> = ({ image, onChange, outputWidth, outputHeight, maxOutputHeight, maxOutputWidth, outpaintMode }) => {
    // Handle bigger screens
    const { devicePixelRatio: ratio = 1 } = window;

    // Get values from editor
    const { previewAssetSrc, loading, outpaintState } = useComponentStore<AssetEditorState>('AssetEditor', {
        fields: {
            croppedModifiedAssetSrc: 'croppedModifiedAssetSrc',
            originalAssetSrc: 'originalAssetSrc',
            modifiedAssetSrc: 'modifiedAssetSrc',
            previewAssetSrc: 'previewAssetSrc',
            loading: 'loading',
            outpaintState: 'outpaintState'
        }
    });

    // Get values from controller
    const { selectedAspectRatio, manualInput, isResetTriggered } = useComponentStore<OutpaintState>('Outpaint', {
        fields: {
            selectedAspectRatio: 'selectedAspectRatio',
            manualInput: 'manualInput',
            isResetTriggered: 'isResetTriggered'
        }
    });

    // Set initial values
    const [imageElement, setImageElement] = useState<HTMLImageElement | null>(null);
    const [selectedArea, setSelectedArea] = useState<SelectedArea | null>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [canvasWidth, setCanvasWidth] = useState(200);
    const [canvasHeight, setCanvasHeight] = useState(200);
    const [context, setContext] = useState<CanvasRenderingContext2D | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [imagePosition, setImagePosition] = useState<ImagePosition | null>(null);
    const [zoomLevel, setZoomLevel] = useState(0.3);
    const [translation] = useState<Point>({ x: 0, y: 0 });
    const [processedMaxOutputWidth, setProcessedMaxOutputWidth] = useState(2048);
    const [processedMaxOutputHeight, setProcessedMaxOutputHeight] = useState(2048);

    // Handle everything to do with drawing on the canvas
    useCanvasDraw(
        context,
        imageElement,
        selectedArea,
        imagePosition,
        canvasWidth,
        canvasHeight,
        zoomLevel,
        translation,
        processedMaxOutputWidth,
        processedMaxOutputHeight,
        ratio,
        setZoomLevel,
        setSelectedArea,
        setImagePosition,
        onChange
    );

    // Handle everything to do with clicking, and dragging
    const { handleMouseDown } = useEventListeners(
        selectedArea,
        zoomLevel,
        canvasWidth,
        canvasHeight,
        ratio,
        imagePosition,
        outpaintMode,
        canvasRef?.current,
        translation,
        processedMaxOutputWidth,
        processedMaxOutputHeight,
        setSelectedArea,
        setImagePosition,
        setZoomLevel
    );

    // Handle resetting the outpaint editor
    useEffect(() => {
        if (isResetTriggered) {
            setImagePosition(null);
            setSelectedArea(null);
            ComponentStoreHelpers.setModel('Outpaint', 'isResetTriggered', false);
        }
    }, [isResetTriggered]);

    // Update max output width and height
    useEffect(() => {
        const results = getMaxWidthAndHeightBasedOnApectRatio(selectedAspectRatio, outputHeight, outputWidth, maxOutputWidth, maxOutputHeight);
        if (processedMaxOutputWidth !== results.maxOutputWidth) {
            setProcessedMaxOutputWidth(results.maxOutputWidth);
        }
        if (processedMaxOutputHeight !== results.maxOutputHeight) {
            setProcessedMaxOutputHeight(results.maxOutputHeight);
        }
        if ((processedMaxOutputWidth !== results.maxOutputWidth || maxOutputHeight !== results.maxOutputHeight) && selectedArea) {
            setSelectedArea(null);
        }
    }, [selectedAspectRatio, outputWidth, outputHeight, maxOutputHeight, maxOutputWidth]);

    // When max width/ height changes update the state to update the outpaint sidebar controller
    useEffect(() => {
        if (processedMaxOutputHeight && processedMaxOutputWidth) {
            ComponentStoreHelpers.setModel('AssetEditor', 'outpaintState', { ...outpaintState, processedMaxOutputWidth, processedMaxOutputHeight });
        }
    }, [processedMaxOutputWidth, processedMaxOutputHeight]);

    // When the manual input values in the sidebar controller changes, update the selected area
    useEffect(() => {
        if (
            selectedArea &&
            manualInput &&
            manualInput.w >= 0 &&
            manualInput.h >= 0 &&
            (selectedArea.width !== manualInput.w || selectedArea.height !== manualInput.h)
        ) {
            const zoom = calculateZoom(manualInput.w, manualInput.h, canvasWidth, canvasHeight);
            setZoomLevel(zoom);

            const canvasCenterX = canvasWidth / 2 / zoom;
            const canvasCenterY = canvasHeight / 2 / zoom;

            const selectedAreaX = canvasCenterX - manualInput.w / 2;
            const selectedAreaY = canvasCenterY - manualInput.h / 2;

            setSelectedArea({ ...selectedArea, x: selectedAreaX, y: selectedAreaY, width: manualInput.w, height: manualInput.h });
        }
    }, [manualInput]);

    // Set canvas container resize observer everytime it changes
    const handleWindowResize = (entries: ResizeObserverEntry[]) => {
        const entry = entries[0];
        if (!entry) return;

        const { width, height } = entry.contentRect;
        const canvas = canvasRef.current;

        if (canvas) {
            // Prevent unnecessary state updates and style changes if dimensions haven't changed
            setCanvasWidth(width);
            setCanvasHeight(height);
            setSelectedArea(null);
            setImagePosition(null);
        }
    };

    useEffect(() => {
        if (containerRef.current) {
            const observer = new ResizeObserver(handleWindowResize);
            observer.observe(containerRef.current);

            return () => observer.disconnect();
        }
    }, [containerRef]);

    // Load the image selected by the user
    useEffect(() => {
        const newImage = new Image();
        newImage.onload = () => setImageElement(newImage);

        newImage.src = previewAssetSrc || image.url;
    }, [image, previewAssetSrc]);

    // Init canvas after resize
    useLayoutEffect(() => {
        if (!loading && !previewAssetSrc) {
            initCanvas();
        }
    }, [previewAssetSrc, canvasHeight, canvasWidth, ratio, loading]);

    const initCanvas = () => {
        const context = canvasRef?.current?.getContext('2d');
        if (context) {
            context.canvas.width = canvasWidth;
            context.canvas.height = canvasHeight;
            context.scale(ratio, ratio);

            setContext(context);
        }
    };

    return (
        <OverlayLoadingIndicatorWrapper text={'Extending image...'} isLoading={loading}>
            {previewAssetSrc && !loading && <AssetEditorImage imageSrc={previewAssetSrc} imageBoxStyle={{ transform: `rotate(0deg) scaleX(1) scaleY(1)` }} />}
            {!previewAssetSrc && !loading && (
                <div ref={containerRef} style={{ display: 'flex', flex: '1' }}>
                    <canvas
                        onMouseDown={handleMouseDown}
                        style={{
                            width: `100%`,
                            height: `100%`
                        }}
                        ref={canvasRef}
                    />
                </div>
            )}
        </OverlayLoadingIndicatorWrapper>
    );
};

export default OutpaintEditorCanvas;
