import { useEffect, useRef, MutableRefObject } from 'react';
import { SelectedArea, ImagePosition, Point, ResizeState, DragState } from '../interfaces/outpaint';
import {
    calculateZoom,
    getMouseCoordsRelativeToCanvas,
    detectUserAction,
    handleDragging,
    handleResizing,
    isPointerInSelectedArea,
    isPointerOnSelectedAreaEdge
} from '../helpers/outpaint-canvas-helpers';

const useEventListeners = (
    selectedArea: SelectedArea | null,
    zoomLevel: number,
    canvasWidth: number,
    canvasHeight: number,
    ratio: number,
    imagePosition: ImagePosition | null,
    outpaintMode: string,
    canvas: HTMLCanvasElement | null,
    translation: Point,
    processedMaxOutputWidth: number,
    processedMaxOutputHeight: number,
    setSelectedArea: React.Dispatch<React.SetStateAction<SelectedArea | null>>,
    setImagePosition: React.Dispatch<React.SetStateAction<ImagePosition | null>>,
    setZoomLevel: React.Dispatch<React.SetStateAction<number>>
) => {
    // Ref for dragging state
    const dragStateRef: MutableRefObject<DragState> = useRef({
        isDragging: false,
        startMousePos: { x: 0, y: 0 },
        startPos: { x: 0, y: 0 },
        layer: ''
    });

    // Ref for resizing state
    const resizeStateRef: MutableRefObject<ResizeState> = useRef({
        isResizing: false,
        startMousePos: { x: 0, y: 0 },
        startPos: { x: 0, y: 0, width: 0, height: 0 },
        resizeDirection: '',
        layer: ''
    });

    // Set up event listeners
    useEffect(() => {
        // Add mouse event listeners
        document.addEventListener('mousemove', mouseMove);
        document.addEventListener('mouseup', mouseUp);

        // Remove event listeners on component unmount
        return () => {
            // document.removeEventListener('wheel', handleWheel);
            document.removeEventListener('mousemove', mouseMove);
            document.removeEventListener('mouseup', mouseUp);
        };
    }, [resizeStateRef, dragStateRef, selectedArea, zoomLevel, canvasWidth, canvasHeight, ratio, imagePosition]);

    /**
     * Handle the moving of the mouse
     */
    const mouseMove = (event: MouseEvent) => {
        if (!canvas) return;

        // Calculate the XY coords of the mouse pointer within the canvas
        const mouseCoords = getMouseCoordsRelativeToCanvas(canvas, event.clientX, event.clientY, zoomLevel);

        if (mouseCoords) {
            updateCursorStyle(mouseCoords);

            // if the mouse is dragging an item
            if (dragStateRef.current.isDragging && selectedArea && imagePosition) {
                // Handle dragging a layer
                const updatedLayer = handleDragging(mouseCoords, dragStateRef.current, imagePosition, selectedArea);
                // For now only image dragging is supported
                setImagePosition(updatedLayer);
            } else if (resizeStateRef.current.isResizing && imagePosition && selectedArea) {
                // Handle resizing a layer
                const updatedLayer = handleResizing(
                    mouseCoords,
                    resizeStateRef.current,
                    imagePosition,
                    selectedArea,
                    processedMaxOutputWidth,
                    processedMaxOutputHeight
                );

                if (updatedLayer) {
                    // Update the adjusted layer state
                    setImagePosition(updatedLayer.imagePosition);
                    setSelectedArea(updatedLayer.selectedArea);
                }
            }
        }
    };

    const mouseUp = () => {
        // When the user let's go of the mouse, recenter the selection and recalc zoom so it is always in view
        if (selectedArea && imagePosition && (resizeStateRef.current.isResizing || dragStateRef.current.isDragging)) {
            // Recalc Zoom
            const zoom = calculateZoom(selectedArea.width, selectedArea.height, canvasWidth, canvasHeight);
            setZoomLevel(zoom);

            const canvasCenterX = canvasWidth / 2 / zoom;
            const canvasCenterY = canvasHeight / 2 / zoom;

            setSelectedArea((prev) => {
                if (prev) {
                    const newSelectedArea = {
                        ...prev,
                        x: canvasCenterX - prev.width / 2,
                        y: canvasCenterY - prev.height / 2
                    };

                    setImagePosition({
                        ...imagePosition,
                        x: newSelectedArea.x + imagePosition.extendLeft,
                        y: newSelectedArea.y + imagePosition.extendUp
                    });

                    return newSelectedArea;
                }
                return prev;
            });
        }

        if (dragStateRef.current.isDragging) {
            dragStateRef.current.isDragging = false;
        }

        if (resizeStateRef.current.isResizing) {
            resizeStateRef.current.isResizing = false;
            resizeStateRef.current.resizeDirection = '';
        }
    };

    /**
     * Handle mouse down and init drag or resize logic (actual move and resize logic is in handleMouseMove)
     */
    const handleMouseDown = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
        if (canvas && selectedArea && imagePosition) {
            const mouseCoords = getMouseCoordsRelativeToCanvas(canvas, event.clientX, event.clientY, zoomLevel);
            const detectedUserAction = detectUserAction(
                event,
                mouseCoords,
                dragStateRef.current,
                resizeStateRef.current,
                canvas,
                selectedArea,
                imagePosition,
                translation,
                zoomLevel
            );
            if (outpaintMode !== 'fixed' && detectedUserAction?.isResizing) resizeStateRef.current = detectedUserAction;
            if (detectedUserAction?.isDragging) dragStateRef.current = detectedUserAction;
        }
    };

    // Inside mouseMove function
    const updateCursorStyle = (mouseCoords: Point) => {
        if (canvas && imagePosition && selectedArea) {
            const isOnEdgeResult = isPointerOnSelectedAreaEdge(mouseCoords, imagePosition, selectedArea, 4 / zoomLevel);

            if (isOnEdgeResult.isOnEdge) {
                // Adjust cursor based on the edge
                if (outpaintMode !== 'fixed' && (isOnEdgeResult.edge === 'left' || isOnEdgeResult.edge === 'right')) {
                    canvas.style.cursor = 'ew-resize';
                }
                if (outpaintMode !== 'fixed' && (isOnEdgeResult.edge === 'top' || isOnEdgeResult.edge === 'bottom')) {
                    canvas.style.cursor = 'ns-resize';
                }
                if (outpaintMode !== 'fixed' && (isOnEdgeResult.edge === 'topLeft' || isOnEdgeResult.edge === 'bottomRight')) {
                    canvas.style.cursor = 'nwse-resize';
                }
                if (outpaintMode !== 'fixed' && (isOnEdgeResult.edge === 'topRight' || isOnEdgeResult.edge === 'bottomLeft')) {
                    canvas.style.cursor = 'nesw-resize';
                }
            } else if (isPointerInSelectedArea(mouseCoords, imagePosition, selectedArea, 4 / zoomLevel).isInSelectedArea) {
                // If inside the selection or image for moving
                canvas.style.cursor = 'move';
            } else {
                // Revert to default when not over interactive areas
                canvas.style.cursor = 'default';
            }
        }
    };

    return { handleMouseDown };
};

export default useEventListeners;
